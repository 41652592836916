function getHeyflowNavId(realId) {
   const navId = Object.keys(window.heyflow).filter(id => id.indexOf(realId) === 0);
   
   if (!navId[0]) console.error('Can not find Heyflow id');
   
   return navId[0];
}

function getElementRoot(realId) {
    const embeddedElement = document.querySelector(`[flow-id="${realId}"]`);
    
    return embeddedElement ? embeddedElement.shadowRoot : document;
}

function getCriteriaKeys(formValues, prefix) {
    return Object.keys(formValues).filter(fieldName => fieldName.indexOf(prefix) === 0);
}

function proceedFlow(event, yesCount, totalCount, successSelector, prefix) {
    const realId = event.detail.flowID;
    const navId = getHeyflowNavId(realId);
    const elementRoot = getElementRoot(realId);

    const formValues = event.detail.fieldsSimple || {};
    const criteriaKeys = getCriteriaKeys(formValues, prefix);
    const checkedCriteria = criteriaKeys.filter(fieldName => formValues[fieldName] === 'Yes');
    
    const pageSuccess = elementRoot.querySelector(successSelector);
    const pageError = elementRoot.querySelector('section[name="sorry-screen"]');

    console.log('formValues', formValues);
    
    if (checkedCriteria.length >= yesCount) {
      window.heyflow[navId].navigation.navigate('go', pageSuccess.id, null, { noValidate: true });
    }
    else if (criteriaKeys.length >= totalCount) {
      window.heyflow[navId].navigation.navigate('go', pageError.id, null, { noValidate: true });
    }
}

window.addEventListener('heyflow-change', (event) => {
    proceedFlow(event, 3, 8, 'section[name="o1a"]', 'criteria');
    proceedFlow(event, 3, 6, 'section[name="o1b"]', 'o1b_criteria');
});